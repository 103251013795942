import React from 'react'
import StartPageHero from './start-page-hero'
import StartPageTeaser from './start-page-teaser'
import StartPageSponsors from './start-page-sponsors'
import StartPageIntro from './start-page-intro'

const StartPage = ({ node }) => {
  return (
    <>
      <StartPageHero node={node} />
      <StartPageIntro title={node.intro_title} text={node.intro_text}/>
      <StartPageTeaser teasers={node.teasers} />
      <StartPageSponsors node={node} />
    </>
  )
}

export default StartPage
