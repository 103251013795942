import React from 'react'
import { RichText } from 'prismic-reactjs'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { breakpoints } from '../../../helpers/media'
import AttachedBackground from '../../../components/attached-background'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  height: 35vh;
  padding-top: 30px;
  justify-content: center;
  @media only screen and (min-width: ${breakpoints.md}px) {
    justify-content: flex-start;
    padding-top: 25vh;
    height: 100vh;
  }
`

const H1 = styled.h1`
  color: white;
  letter-spacing: 0px;
  text-shadow: 0px 3px 6px #000000be;
  opacity: 1;
  text-align: center;
  font-size: 32px;
  max-width: 85vw;
  @media only screen and (min-width: ${breakpoints.md}px) {
    font-size: 75px;
  }
`
const H2 = styled.h2`
  color: white;
  font-size: 18px;
  letter-spacing: 0px;
  text-shadow: 0px 3px 6px #000000ba;
  opacity: 1;
  text-align: center;
  max-width: 85vw;
  @media only screen and (min-width: ${breakpoints.md}px) {
    font-size: 45px;
  }
`

const ImgWrapper = styled.div`
  display: none;
  @media only screen and (min-width: ${breakpoints.md}px) {
    display: block;
  }
`
const StartPageHero = ({ node }) => {
  return (
    <div
      style={{ maxWidth: '100vw', overflowX: 'hidden', overflowY: 'hidden' }}
    >
      <AttachedBackground
        url={node.hero_background_image.url}
        top
        className='hero-animation'
      >
        <Wrapper className='animate-in'>
          <ImgWrapper>
            <Img
              style={{
                width: '237px',
                height: '237px'
              }}
              imgStyle={{
                objectFit: 'contain'
              }}
              fluid={node.hero_logo_imageSharp.childImageSharp.fluid}
            />
          </ImgWrapper>
          <H2>{RichText.asText(node.hero_title_1)}</H2>
          <H1>{RichText.asText(node.hero_title_2)}</H1>
          <H2>{RichText.asText(node.hero_title_3)}</H2>
        </Wrapper>
      </AttachedBackground>
    </div>
  )
}

export default StartPageHero
