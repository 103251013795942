import React from 'react'
import styled from 'styled-components'
import { breakpoints } from '../../helpers/media'
import { RichText } from 'prismic-reactjs'
import Img from 'gatsby-image'
import { DEFAULT_COLOR, DEFAULT_GREY_TITLE } from '../../helpers/colors'
import Badge from '../badge'
import useTranslation from '../../hooks/use-translation'
import CustomRichText from '../custom-rich-text'

const Wrapper = styled.div(
  () => `
  position: relative;
  display: flex;
  flex-direction: column;
  width: 75vw;
  border-radius: 10px;
  margin-top: 75px;
  margin-right: 20px;
  transition: max-height 0.5s ease-in-out;
  box-shadow: 0px 0px 3px 1px #0000003d;
  @media only screen and (max-width: ${breakpoints.md}px) {
    flex-direction: column;
  }
`
)

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-top: 30px;
`

const ImgWrapper = styled.div`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 75vw;
  min-height: 200px;
  height: 100px;
  overflow: hidden;
`

const TextWrapper = styled.div(
  ({}) => `
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
`
)
const TextInnerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const TeaserTitle = styled.h3`
  margin-bottom: 20px;
  text-shadow: 0px 2px 3px #0000001f;
  color: ${DEFAULT_GREY_TITLE};
`

const TeaserText = styled.div`
  text-shadow: 0px 2px 3px #00000014;
  color: #606676;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
`

const DesktopTeaser = ({
  fluid,
  title,
  text,
  longText,
  link,
  href,
  setOpen
}) => {
  const t = useTranslation()
  return (
    <Wrapper>
      <ImgWrapper>
        <Img
          fluid={fluid}
          style={{ height: '100%' }}
          imgStyle={{ objectFit: 'cover' }}
        />
      </ImgWrapper>
      <ContentWrapper>
        <TextWrapper>
          <TextInnerWrapper>
            <TeaserTitle>{RichText.asText(title)}</TeaserTitle>
            <TeaserText>
              <CustomRichText richText={text} />
            </TeaserText>
            <Row>
              {longText && (
                <Badge backgroundColor={DEFAULT_COLOR} color="white" width='auto' onClick={setOpen}>{t`Read more`}</Badge>
              )}
              {link && (
                <Badge backgroundColor={DEFAULT_COLOR} color="white" width='75px' href={href}>
                  {RichText.asText(link)}
                </Badge>
              )}
            </Row>
          </TextInnerWrapper>
        </TextWrapper>
      </ContentWrapper>
    </Wrapper>
  )
}

export default DesktopTeaser
