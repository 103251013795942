import React from 'react'
import FullWidthTeaser from '../../../components/full-width-teaser'
import styled from 'styled-components'
import { breakpoints } from '../../../helpers/media'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  position: relative;
  padding: 20px;
  width: 100vw;
  margin-bottom: 30px;
  @media only screen and (min-width: ${breakpoints.lg}px) {
    padding: 0px;
    border-top: solid 100px white;
    border-left: solid 100px white;
    border-right: solid 100px white;
    flex-direction: column;
    margin-bottom: 0px;
  }
`
const StartPageTeaser = ({ teasers }) => {
  return (
    <Wrapper>
      {teasers.map((teaser, index) => (
        <FullWidthTeaser
          key={index}
          fluid={teaser.imageSharp.childImageSharp.fluid}
          title={teaser.teaser_title}
          text={teaser.teaser_text}
          invert={false}
        />
      ))}
    </Wrapper>
  )
}

export default StartPageTeaser
