import React, { useState } from 'react'
import { Media } from '../../helpers/media'
import DesktopTeaser from './desktop-teaser'
import MobileTeaser from './mobile-teaser'
import TeaserReadMoreDialog from './teaser-read-more-dialog'

const FullWidthTeaser = props => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Media lessThan='lg'>
        <MobileTeaser {...props} setOpen={() => setOpen(true)}/>
      </Media>
      <Media greaterThan='md'>
        <DesktopTeaser {...props} setOpen={() => setOpen(true)} />
      </Media>
      <TeaserReadMoreDialog
        close={() => setOpen(false)}
        open={open}
        {...props}
      />
    </>
  )
}

export default FullWidthTeaser
