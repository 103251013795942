import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import StartPage from '../views/start-page'
import { graphql } from 'gatsby'
import useLanguage from '../hooks/use-language'

const IndexPage = ({ data }) => {
  const language = useLanguage()
  let node = data.prismic.allFirstPages.edges.filter(x => x.node._meta.lang === language.locale)[0].node || data.prismic.allFirstPages.edges[0].node
  return (
    <Layout>
      <SEO title="Industriell Ekonomi" />
      <StartPage node={node}/>
    </Layout>
  )
}

export const query = graphql`
  {
    prismic {
      allFirstPages {
        edges {
          node {
            _meta {
              lang
            }
            title
            hero_background_image
            hero_logo_image
            hero_logo_imageSharp {
              childImageSharp {
                fluid(quality: 90, maxWidth: 300) {
                 ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            hero_title_1
            hero_title_2
            hero_title_3
            intro_title
            intro_text
            teasers {
              image
              imageSharp {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1000) {
                   ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              teaser_title
              teaser_text
            }
            main_sponsor_image
            main_sponsor_imageSharp {
              childImageSharp {
                fluid(quality: 90, maxWidth: 500) {
                 ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            main_sponsor_website {
              ... on PRISMIC__ExternalLink {
                target
                url
              }
            }
            collaborators {
              website {
                ... on PRISMIC__ExternalLink {
                  target
                  url
                }
              }
              collaborator_image
              collaborator_imageSharp {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 300) {
                   ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            sponsor_background_image
            sponsor_background_imageSharp {
              childImageSharp {
                fluid(quality: 50, maxWidth: 1920) {
                 ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
