import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import useTranslation from '../../../hooks/use-translation'
import AttachedBackground from '../../../components/attached-background'
import { breakpoints } from '../../../helpers/media'

const Wrapper = styled.div`
  width: 100vw;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
  ::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #e6dddbc4;
  }
`

const InnerWrapper = styled.div`
  position: relative;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  z-index: 2;
`

const CollabWrapper = styled.div`
  width: 1100px;
  max-width: 100vw;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  justify-self: center;
  align-self: center;
  place-self: center;
  padding-bottom: 50px;
`

const H1 = styled.h1`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 75px;
  font-weight: 300;
  font-size: 25px;
  letter-spacing: 0px;
  color: #4c4d4f;
  text-shadow: 0px 3px 6px #0000001d;
  opacity: 1;
  text-transform: uppercase;
  ::after {
    content: '';
    display: block;
    margin-top: 10px;
    width: 70px;
    height: 3px;
    transition: width 0.3s;
    transition-delay: 0.1s;
  }
  @media only screen and (min-width: ${breakpoints.md}px) {
    font-size: 38px;
  }
`

const StartPageSponsors = ({ node }) => {
  const t = useTranslation()
  const {
    main_sponsor_imageSharp,
    main_sponsor_website,
    collaborators,
    sponsor_background_imageSharp
  } = node
  return (
      <Wrapper>
        <InnerWrapper>
          <H1>{t`Main Sponsor`}</H1>
          <a href={main_sponsor_website ? main_sponsor_website.url : ''} target={main_sponsor_website ? main_sponsor_website.target : ''}>
            <Img
              style={{
                maxWidth: '100vw',
                width: '650px',
                justifySelf: 'center',
                marginTop: '35px'
              }}
              fluid={main_sponsor_imageSharp.childImageSharp.fluid}
            />
          </a>
          <H1>{t`Partners`}</H1>
          <CollabWrapper>
            {collaborators.map((collab, index) => (
              <a
                key={index}
                style={{ display: 'grid' }}
                href={collab.website ? collab.website.url : ''} target={collab.website ? collab.website.target : ''}
              >

                <Img
                  style={{ width: '300px', justifySelf: 'center' }}
                  imgStyle={{ objectFit: 'contain' }}
                  fluid={collab.collaborator_imageSharp.childImageSharp.fluid}
                />
              </a>
            ))}
          </CollabWrapper>
        </InnerWrapper>
      </Wrapper>
  )
}

export default StartPageSponsors
