import React from 'react'
import { Dialog } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'
import { DEFAULT_COLOR } from '../../helpers/colors'
import { breakpoints } from '../../helpers/media'
import Badge from '../badge'
import Img from 'gatsby-image'
import CustomRichText from '../custom-rich-text'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const Wrapper = styled.div`
  display: block;
  color: #4c4d4fe5;
  padding: 10px;
  & p {
    margin: 10px 0px;
  }

  @media only screen and (min-width: ${breakpoints.md}px) {
    padding: 60px;
  }
`

const ImgWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  @media only screen and (max-width: ${breakpoints.md}px) {
    width: 75vw;
  }
  @media only screen and (min-width: ${breakpoints.md}px) {
    height: 250px;
  }
`
const Title = styled.h3`
  margin-bottom: 20px;
  text-shadow: 0px 2px 3px #0000001f;
`

const BadgeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const TextWrapper = styled.div`
  font-size: 12px;
  @media only screen and (min-width: ${breakpoints.md}px) {
    font-size: 18px;
  }
`

const TeaserReadMoreDialog = ({
  open,
  fluid,
  title,
  longText,
  href,
  link,
  close
}) => {
  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth='md'
      fullWidth
      TransitionComponent={Transition}
    >
      {!!longText && (
        <Wrapper>
          <ImgWrapper>
            <Img
              fluid={fluid}
              style={{ height: '100%' }}
              imgStyle={{ objectFit: 'contain' }}
            />
          </ImgWrapper>
          <Title>{RichText.asText(title)}</Title>
          <TextWrapper>
            <CustomRichText richText={longText} />
          </TextWrapper>
          <BadgeWrapper>
            {link && (
              <Badge color='white' backgroundColor={DEFAULT_COLOR} href={href}>
                {RichText.asText(link)}
              </Badge>
            )}
          </BadgeWrapper>
        </Wrapper>
      )}
    </Dialog>
  )
}

export default TeaserReadMoreDialog
