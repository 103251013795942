import React from 'react'
import styled from 'styled-components'
import { breakpoints } from '../../helpers/media'
import { RichText } from 'prismic-reactjs'
import Img from 'gatsby-image'
import Badge from '../badge'
import {
  DEFAULT_BACKGROUND_RGBA,
  DEFAULT_GREY_TITLE,
  DEFAULT_COLOR
} from '../../helpers/colors'
import useTranslation from '../../hooks/use-translation'
import CustomRichText from '../custom-rich-text'

const Wrapper = styled.div(
  props => `
  display: flex;
  flex-direction: row;
  flex-direction: ${props.invert ? 'row-reverse' : ''};
  width: 100%;
  height: 400px;
  margin-top: 60px;
  margin-bottom: 0px;
`
)
const ImageWrapper = styled.div`
  width: 50%;
  height: 400px;
  @media only screen and (max-width: ${breakpoints.lg}px) {
    position: absolute;
    width: 100%;
  }
`
const TextWrapper = styled.div(
  ({ invert }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 400px;
  padding: 0px 60px 0px 60px;
  opacity: 1;
  color: ${DEFAULT_GREY_TITLE};
  @media only screen and (max-width: ${breakpoints.lg}px) {
    position: absolute;
    width: 100%;
    background-color: ${DEFAULT_BACKGROUND_RGBA(0.9)};
  }
`
)
const TextInnerWrapper = styled.div`
  color: #606676 !important;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 750px;
`

const TeaserTitle = styled.h3`
  color: ${DEFAULT_GREY_TITLE};
  margin-bottom: 20px;
  text-shadow: 0px 2px 3px #0000001f;
  @media only screen and (max-width: ${breakpoints.lg}px) {
    text-shadow: 0px 3px 3px #00000095;
  }
`

const TeaserText = styled.div`
  text-shadow: 0px 2px 3px #00000014;
  @media only screen and (max-width: ${breakpoints.lg}px) {
    text-shadow: 0px 3px 3px #00000085;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 25px;
`

const DesktopTeaser = ({
  fluid,
  title,
  text,
  longText,
  invert,
  link,
  href,
  setOpen
}) => {
  const t = useTranslation()
  return (
    <>
      <Wrapper invert={invert}>
        <ImageWrapper>
          {fluid && (
            <Img
              fluid={fluid}
              style={{
                width: '100%',
                height: '400px',
              }}
              imgStyle={{
                objectPosition: 'center center',
                objectFit: 'contain'
              }}
              alt={RichText.asText(title)}
            />
          )}
        </ImageWrapper>
        <TextWrapper invert={invert}>
          <TextInnerWrapper>
            <TeaserTitle>{RichText.asText(title)}</TeaserTitle>
            <TeaserText>
              <CustomRichText richText={text} />
            </TeaserText>
            <Row>
              {longText && <Badge backgroundColor={DEFAULT_COLOR} color="white" onClick={setOpen}>{t`Read more`}</Badge>}
              {link && <Badge backgroundColor={DEFAULT_COLOR} color="white" href={href}>{RichText.asText(link)}</Badge>}
            </Row>
          </TextInnerWrapper>
        </TextWrapper>
      </Wrapper>
    </>
  )
}

export default DesktopTeaser
