import React from 'react'
import styled from 'styled-components'
import { breakpoints } from '../../../helpers/media'
import { RichText } from 'prismic-reactjs'
import CustomRichText from '../../../components/custom-rich-text'
import { DEFAULT_GREY_TITLE } from '../../../helpers/colors'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100vw;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-top: 125px;
  z-index: 10000;
  position: relative;
  background-color:
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 850px;
  max-width: 80vw;
  margin-right: 80px;
  & p {
    font-size: 18px;
    color: #606676;
    text-shadow: 0px 2px 3px #00000014;
    opacity: 1;
  }
  @media only screen and (max-width: ${breakpoints.lg}px) {
    width: 80vw;
    margin-right: 0px;
  }
`

const Title = styled.h3`
  font-size: 38px;
  letter-spacing: 0px;
  color: ${DEFAULT_GREY_TITLE};
  text-shadow: 0px 2px 3px #0000001f;
  opacity: 1;
  margin-bottom: 20px;
`

const StartPageIntro = ({ title, text }) => {
  return (
    <Wrapper>
      <TextWrapper>
        <Title>{RichText.asText(title)}</Title>
        <CustomRichText richText={text} />
      </TextWrapper>
    </Wrapper>
  )
}

export default StartPageIntro
